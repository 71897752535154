<template>
    <q-layout  view="hHh lpR fff"> <!-- :theme="store.theme" -->
        <HeaderMain />
        <q-page-container fluid class="container">
            <loader v-if="store.loader" />
            <slot />
        </q-page-container>
    </q-layout>
</template>
<script setup>
import { useStore } from '@/store/store';

const store = useStore();

const showHideSpinner = ref(true);
onMounted(() => {
    setTimeout(() => {
        showHideSpinner.value = false;
    }, 2000);
});
</script>
<style scoped lang="scss">
.container {
    height: 100dvh;
}
</style>
